import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BackEndUrlService } from './services/backend.url.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  msgs: any[] = [];
  private mySubscription: any;

  //constructor(
  //  private authService: AuthService,
  //  private router: Router,
  //  private activatedRoute: ActivatedRoute
  //) {
  //  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //  this.mySubscription = this.router.events.subscribe((event) => {
  //   // debugger;
  //    if (event instanceof NavigationEnd) {
  //      if (event.urlAfterRedirects !== '/login') {

  //        /*sessionStorage.setItem(`lastUrl`, JSON.stringify(event.urlAfterRedirects));*/
  //        sessionStorage.setItem(`lastUrl`, event.urlAfterRedirects);
  //      }
  //    }
  //  });
  //  window.addEventListener('storage', this.handleStorageEvent.bind(this));
  //}
  localUrl: string;
  liveUrl: string;
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute, private backEndUrlService: BackEndUrlService
  ) {
    this.localUrl = this.backEndUrlService.localUrl;
    this.liveUrl = this.backEndUrlService.liveUrl;
    if (localStorage.getItem('authToken')) {
      this.authService.setUserDetails();
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this.router.events.subscribe((event) => {
      // debugger;
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects !== '/login') {

          /*sessionStorage.setItem(`lastUrl`, JSON.stringify(event.urlAfterRedirects));*/
          sessionStorage.setItem(`lastUrl`, event.urlAfterRedirects);
        }
      }
    });
    window.addEventListener('storage', this.handleStorageEvent.bind(this));
  }
  ngOnInit() {
    this.handleInitialNavigation();
  }
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
    window.removeEventListener('storage', this.handleStorageEvent.bind(this));
  }
  private handleInitialNavigation() {
 //debugger;

   
    const authToken = localStorage.getItem('authToken');

    const url = window.location.pathname;
    const previousUrl = sessionStorage.getItem('previousUrl');

    if (authToken) {
      this.authService.setUserDetails();
      const fullUrl = window.location.href;
      const url = window.location.pathname;
      const lastUrl = sessionStorage.getItem('lastUrl');
      //debugger;


      if (!fullUrl.includes('/assets/Files/')) {

        if (fullUrl.includes(this.localUrl)) {
          // If the user is on localhost:49595 (root or any path), navigate to the dashboard
          if (url !== '/login' && url === '/') {
            this.router.navigate(['/admin/dashboard']);
          }
        }
        if (fullUrl.includes(this.liveUrl)) {
          // If the user is on localhost:49595 (root or any path), navigate to the dashboard
          if (url !== '/login' && url === '/') {
            this.router.navigate(['/admin/dashboard']);
          }
        }
      }
      // Handle the case where the user pastes a URL in a new tab
      if (url && url !== '/login' && url !== '/') {
        sessionStorage.setItem('lastUrl', url);
        this.router.navigate([url]); // Navigate to the pasted URL
      }
      // If there's a lastUrl stored and it's not /login, navigate to it
      else if (lastUrl && lastUrl !== '/login') {
        this.router.navigate([lastUrl]);
        sessionStorage.removeItem('lastUrl');
      }
      else if (previousUrl && previousUrl !== '/login' && lastUrl == '/login') {
        this.router.navigate([previousUrl]);
        sessionStorage.removeItem('previousUrl');
      }
      // Otherwise, navigate to the dashboard by default
      else {
        this.router.navigate(['/admin/dashboard']);
      }
    } else {
      this.router.navigate(['/login']);
    }

 
    //else {
    //  this.router.navigate(['/login']);
    //}
  }
  private handleStorageEvent(event: StorageEvent) {
    if (event.key === 'authToken') {
      if (event.newValue) {
        this.authService.setUserDetails();
        this.handleInitialNavigation();
      } else {
          sessionStorage.setItem(`previousUrl`, sessionStorage.getItem(`lastUrl`) || '');
       
        sessionStorage.setItem(`lastUrl`, this.router.url);


        // this.authService.logout();
        this.authService.logoutUserName();
        //setTimeout(() => {
        //  this.router.navigate(['/login']);
        //}, 100);
        this.router.navigate(['/login']); 
      }
    }
  }


}
