import { Component, OnInit, ViewChild, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { TaskSetting } from './task/taskSetting';
import { AuthService } from '../services/auth.service';
import { userModel } from '../Model/userDetails';
import { HttpClientService } from '../services/http.client.service';
import { BackEndUrlService } from '../services/backend.url.service';
import { Idle, DEFAULT_INTERRUPTSOURCES, IdleExpiry, LocalStorageExpiry } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Subscribable, from } from 'rxjs';
import { getUniqueID } from '@syncfusion/ej2-base';
import { Tooltip, TooltipEventArgs } from '@syncfusion/ej2-popups';
import { Console } from 'console';
import { BehaviourSubjectServiceService } from '../services/behaviour-subject-service.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  providers: [HttpClientService, BackEndUrlService, LocalStorageExpiry, { provide: IdleExpiry, useExisting: LocalStorageExpiry }, Idle]
})

export class AdminComponent implements OnInit {
  @Output() searchValue = new EventEmitter();
  filterValue = '';
  previousUrl: string = null;
  currentUrl: string = null;
  userDataSubscription: any;
  userData = new userModel();
  login: any;
  FirstName: string;
  LastName: string;
  imgURL: any;
  imgShow: boolean = false;
  userObject: any;
  userid: any;
  response: any;
  ErrorMessage: string = "";
  IsShowError: boolean = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  ProductAuctionTitlePendingCount: number = 0;
  ProgressBar: number = 0;
  ServiceStartTime: string = ''
  SeviceChangesDone: string = '';
  getPendingNotification: any = [];
  menuList: any = [];

  public modalRef: BsModalRef;
  public isTimeOut: Boolean = false;
  @ViewChild('childModal', { static: false }) childModal: ModalDirective;
  @ViewChild('Notification', { static: false }) Notification: ModalDirective;
  NumberOfNotification: number = 0;
  impactedProducts: any;
  settingMenuList: any = [];
  subscription: any;
  mySubscription;

  constructor(private elementRef: ElementRef, private taskSetting: TaskSetting, private authService: AuthService, private httpService: HttpClientService, private idle: Idle, private keepalive: Keepalive, private router: Router, private activatedRoute: ActivatedRoute, private elem: ElementRef, private dataService: BehaviourSubjectServiceService, private modalService: BsModalService) {
    const a = getUniqueID();
    this.idle.setIdleName(a.toString());
    this.authService.setUserDetails();
    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
    });
    this.userDataSubscription = this.authService.menuList.asObservable().subscribe(data => {
      this.menuList = data.filter(x => x.parentHeaderName != 'Catalog Library' && x.parentHeaderName != 'User Management');
      this.settingMenuList = data.filter(x => x.parentHeaderName == 'Catalog Library' || x.parentHeaderName == 'User Management');
      if (this.menuList.length > 0) {
        this.menuList = this.menuList.filter(x => x.isShowInMenu);
      }
      if (this.settingMenuList.length > 0) {
        this.settingMenuList = this.settingMenuList.filter(x => x.isShowInMenu);
      }
    });

    //idle.setIdle(30); // Idle time before considering user inactive

    // Set the timeout period to 1 minute (60 seconds) after the idle period
    // idle.setTimeout(30);
      idle.setIdle(1800); 
    idle.setTimeout(30);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      const url = this.router.routerState.snapshot.url;
      if (localStorage.getItem('authToken')) {
        const decodeUserDetails = JSON.parse(window.atob(localStorage.getItem('authToken').split('.')[1]));
        localStorage.setItem('lastUrl', url);
        localStorage.setItem('userName', decodeUserDetails.sub);
        localStorage.setItem('userId', decodeUserDetails.userID);
      }
      localStorage.setItem('logout-event', Date.now().toString());
      this.authService.setUserDetails();
      // sessionStorage.setItem('lastUrl',window.location.pathname);
      //   console.log('aa1 ',localStorage.getItem('authToken'));
      this.router.navigate(['/login']);
      this.childModal.hide();
    });

    idle.onIdleStart.subscribe(() => {
      console.log('getInterrupts ', this.idle.getInterrupts())

      console.log('this.modalService.getModalsCount() ', this.modalService.getModalsCount())
      debugger;
      for (let i = this.modalService.getModalsCount(); i > 0; i--) {
        try { this.modalService.hide(i) }
        catch (err) {
          console.log('error ', err);
        }
      }
      console.log('getInterrupts 2nd ', this.idle.getInterrupts())
      this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'

      console.log('getInterrupts 3rd ', this.idle.getInterrupts())

      if (countdown == 1)
        sessionStorage.setItem('timeOut', JSON.stringify(true))
    });

    // sets the ping interval to 15 seconds
    // keepalive.interval(15);
    keepalive.interval(15)
    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.reset();


  }
  private handleStorageEvent(event: StorageEvent) {
    if (event.key === 'logout-event') {
      // this.authService.logout();  // Trigger logout
      this.authService.logoutUserName();
      for (let i = this.modalService.getModalsCount(); i > 0; i--) {
        try { this.modalService.hide(i) }
        catch (err) {
          console.log('error ', err);
        }
      }
      sessionStorage.setItem('timeOut', JSON.stringify(true))
      this.router.navigate(['/login']);  // Redirect to the login page
    } else if (event.key === 'authToken') {
      if (event.newValue) {
        this.authService.setUserDetails();
        this.handleInitialNavigation();
      }
    }
  }
  private handleInitialNavigation() {


    //const authToken = localStorage.getItem('authToken');

    //const url = window.location.pathname;

    //if (authToken) {
    //  this.authService.setUserDetails();
    //  //const lastUrl = JSON.parse(sessionStorage.getItem(`lastUrl`));
    //  const lastUrl = sessionStorage.getItem(`lastUrl`);
    //  if (lastUrl && lastUrl !== '/login') {
    //    this.router.navigate([lastUrl]);
    //    sessionStorage.removeItem('lastUrl');
    //  }
    //  else if(url && url !== '/login'){
    //    this.router.navigate([url])
    //  }
    //  //if (url && url !== '/login') {
    //  //  this.router.navigate([url]);
    //  //} else if (lastUrl && lastUrl !== '/login') {
    //  //  this.router.navigate([lastUrl]);
    //  //}
    //  else {
    //    this.router.navigate(['/admin/dashboard']);
    //  }
    //}
    const authToken = localStorage.getItem('authToken');

    const url = window.location.pathname;
    const previousUrl = sessionStorage.getItem('previousUrl');
    //debugger;
    if (authToken) {
      this.authService.setUserDetails();

      const url = window.location.pathname;
      const lastUrl = sessionStorage.getItem('lastUrl');
      //  debugger;
      // Handle the case where the user pastes a URL in a new tab
      if (url && url !== '/login') {
        sessionStorage.setItem('lastUrl', url);
        this.router.navigate([url]); // Navigate to the pasted URL
      }
      // If there's a lastUrl stored and it's not /login, navigate to it
      else if (lastUrl && lastUrl !== '/login') {
        this.router.navigate([lastUrl]);
        sessionStorage.removeItem('lastUrl');
      }
      else if (previousUrl && previousUrl !== '/login' && lastUrl == '/login') {
        this.router.navigate([previousUrl]);
        sessionStorage.removeItem('previousUrl');
      }
      // Otherwise, navigate to the dashboard by default
      else {
        this.router.navigate(['/admin/dashboard']);
      }
    } else {
      this.router.navigate(['/login']);
    }


    //else {
    //  this.router.navigate(['/login']);
    //}
  }

  ngOnInit() {
    // this.authService.usereImage.subscribe(res=>{
    //   if(res!='')
    //    this.imgURL=res;

    //   // if(['',null,undefined].indexOf
    //   // (res)!=-1)
    //   // this.imgShow=false;
    //   // else{
    //   //   this.imgShow=true
    //   // }
    //  },error=>{
    //   alert(JSON.stringify(error))
    //  })
    window.addEventListener('storage', this.handleStorageEvent.bind(this));
    this.ProductAuctionTitlePendingCount = 0;
    this.NumberOfNotification = 0;
    this.ProgressBar = 0
    this.ServiceStartTime = ''
    this.userObject = { ProfilePicture: '' }
    this.FetchUserDetails();
    //   this.handleInitialNavigation();
    this.subscription = this.dataService.data$.subscribe(
      newData => {
        this.imgURL = newData;
      }
    );
    // this.GetPendingNotifications();

  }
  @HostListener('body:click', ['$event'])
  DocumentClick(event: Event) {
    let sublinkDropdown = document.getElementsByClassName('sublinkDropdown')[0];
    if (this.elem.nativeElement.contains(event.target)) {
      sublinkDropdown.classList.add('hide');
      sublinkDropdown.classList.remove('show');

    }
  }

  openCogMenu() {

    let sublinkDropdown = document.getElementsByClassName('sublinkDropdown')[0];
    if (sublinkDropdown.classList.contains('show')) {
      sublinkDropdown.classList.remove('show');
    }
    else {
      setTimeout(() => {
        sublinkDropdown.classList.add('show');
      }, 10)
    }
  }

  filter() {
    this.searchValue.emit(this.filterValue);
  }
  ngOnDestroy() {
    window.removeEventListener('storage', this.handleStorageEvent.bind(this));
  }

  FetchUserDetails() {
    var observable = this.httpService.get('api/User/GetUserById' + "/" + this.userData.userId);
    observable.subscribe(
      (res: any) => {
        if (res.status > 0) {
          console.log(res);

          if (res.status == 200) {
            // Log values to verify before storing them
            console.log("IsCameraAutoClick: ", res.userDisplayVM.isCameraAutoClick ? '1' : '0');
            console.log("AutoClickSeconds: ", res.userDisplayVM.autoClickSeconds);

            // Save values in localStorage
            localStorage.setItem('IsCameraAutoClick', res.userDisplayVM.isCameraAutoClick ? '1' : '0');
            localStorage.setItem('AutoClickSeconds', String(res.userDisplayVM.autoClickSeconds));
          }
          this.userObject = res.userDisplayVM;
          if (this.userObject != undefined && this.userObject != null) {
            if (this.userObject.profilePicture != null) {
              this.imgShow = true;
              this.imgURL = this.userObject.profilePicture;
              if (this.imgURL == undefined || this.imgURL == null || this.imgURL == '') {
                this.imgURL = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLFz2gM_6ry4WsPSuB43GzBdY1stxVyGLRDkTuPiANxLjwR6RS";
              }
            }
          }
        }
      });
  }

  currentTheme = 'dark-theme';
  triggeredEvents = [];

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.reset();
    this.childModal.hide();
  }

  logout() {
    this.userid = this.userData.userId;
    console.log("your data is", this.userData.userId);
    var obj = { UserID: this.userid };
    this.httpService.get('isLoggedInStatus/' + this.userid)
      .subscribe(data => {
        this.response = data;
        if (this.response.status == 409 || this.response.status == 500) {
          this.IsShowError = true;
        }
        else {
          this.IsShowError = true;
        }
        this.childModal.hide();
        this.authService.logout();
        localStorage.setItem('lastUrl', '');
        localStorage.removeItem('authToken');
        this.router.navigate(['/login']);
      }, error => this.ErrorMessage = error);
    /*-------------------------------------------------------------------------*/
  }

  getPendingNotificationStatus() {
    this.GetPendingNotifications().then((result: any) => {
      this.Notification.show()
    });
  }

  GetPendingNotifications() {
    this.getPendingNotification = [];
    return new (Promise as any)((resolve) => {
      this.httpService.get('api/Product/GetPendingNotifications').subscribe((data: any) => {
        if (data == 0) {
          this.GetPendingNotifications();
        } else {
          this.getPendingNotification = <any>data;
          if (this.getPendingNotification != null) {
            this.NumberOfNotification = this.getPendingNotification.length;
            for (var item of this.getPendingNotification) {
              item['SeviceChangesDone'] = '';
              if (item['lookUpListIDs'] != '' && item['lookUpListIDs'] != null && item['lookUpListIDs'] != undefined) {
                if (item['SeviceChangesDone'] == '') {
                  item['SeviceChangesDone'] += item['lookUpListIDs'];
                }
                else {
                  item['SeviceChangesDone'] += ', ' + item['lookUpListIDs'];
                }
              }
              if (item['auctionTitleRuleIDs'] != '' && item['auctionTitleRuleIDs'] != null && item['auctionTitleRuleIDs'] != undefined) {
                if (item['SeviceChangesDone'] == '') {
                  item['SeviceChangesDone'] += item['auctionTitleRuleIDs'];
                }
                else {
                  item['SeviceChangesDone'] += ', ' + item['auctionTitleRuleIDs'];
                }
              }
              if (item['businessRuleIDs'] != '' && item['businessRuleIDs'] != null && item['businessRuleIDs'] != undefined) {
                if (item['SeviceChangesDone'] == '') {
                  item['SeviceChangesDone'] += item['businessRuleIDs'];
                }
                else {
                  item['SeviceChangesDone'] += ', ' + item['businessRuleIDs'];
                }
              }
            }
          }
        }
        resolve(true);
      })
    });
  }
  navigatetotesting() {
    this.router.navigateByUrl('admin/productcatesting')
  }
  navigatetoAddUpdateProduct() {
    debugger
    this.router.navigateByUrl('admin/add-update-product')
  }
  //private handleInitialNavigation() {
  // debugger;

  // const authToken = localStorage.getItem('authToken');

  //  const url = window.location.pathname;

  //if (authToken) {
  //  debugger
  //    this.authService.setUserDetails();
  // //const lastUrl = JSON.parse(sessionStorage.getItem(`lastUrl`));
  //   const lastUrl = sessionStorage.getItem(`lastUrl`);
  //    if (lastUrl && lastUrl !== '/login') {
  //   //  this.router.navigateByUrl(String(lastUrl).replace('/admin',''));
  //   this.router.navigate([lastUrl]);
  //   } 
  //   else if(url && url !== '/login'){
  //      this.router.navigate([lastUrl]);
  //    }
  //  else {
  //    // this.router.navigate(['/admin/dashboard']);
  //     this.router.navigate(['admin/dashboard']);
  //   }
  //  } 

  // else {
  //    this.router.navigate(['/login']);
  //}
  //}

  //private handleStorageEvent(event: StorageEvent) {
  //if (event.key === 'authToken') {
  // if (event.newValue) {
  //   this.authService.setUserDetails();
  //  this.handleInitialNavigation();
  // } else {
  //       sessionStorage.setItem(`previousUrl`, sessionStorage.getItem(`lastUrl`) || '');

  //    sessionStorage.setItem(`lastUrl`, this.router.url);

  //     this.authService.logout();
  //     //setTimeout(() => {
  //     //  this.router.navigate(['/login']);
  //      //}, 100);
  //     this.router.navigate(['/login']); 
  //    }
  //  }
  //}
}
