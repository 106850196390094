import { Injectable } from '@angular/core';
@Injectable()
export class DownloadService {
  // downloadPath: string = '';
  // pendingDownloadFile = "";
  downloadFile(downloadPath: string) {
    if (downloadPath != "") {
      var element = document.createElement('a');
      element.setAttribute('href', downloadPath);
      element.setAttribute('target', '_blank');
      const fileName = downloadPath.split('/').pop() || 'file';
      element.setAttribute('download', fileName);
      element.click();
      element.remove();
      return true;
    }
    else {
      return false;
    }
  }
}
